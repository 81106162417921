<template>
  <b-form-group
    label-cols="12"
    label-cols-lg="2"
    label-size="sm"
    :label="subject.title"
    :label-for="subject.id"
  >
    <b-form-select
      v-model="selected"
      :state="validateError ? false : null"
      :options="options"
      :disabled="subject.disabled"
    ></b-form-select>
    <small v-if="subject.memo" class="form-text text-muted">
      {{ subject.memo }}
    </small>
    <b-form-invalid-feedback :state="!validateError">
      輸入的資料有誤或未填寫，請確認
    </b-form-invalid-feedback>
  </b-form-group>
</template>

<script>
import collectionApi from "@/apis/collection";

export default {
  props: {
    subject: {
      type: Object,
      required: true,
    },
    value: [String, Number, Array, Object],
    validateError: {
      type: Boolean,
    },
  },
  data() {
    return {
      options: [],
      collection: null,
    }
  },
  watch: {
    subject: {
      handler: function (val) {
        console.log(val);
        if (val.collection_id) {
          this.fetchCollectionItems();
        }
      },
      immediate: true,
    },
  },
  computed: {
    selected: {
      get() {
        return this.value;
      },
      set(v) {
        this.$emit("input", v);
      }
    }
  },
  methods: {
    async fetchCollectionItems() {
      // fetch collection items
      try {
        const response = await collectionApi.getCollection(this.subject.collection_id);

        this.collection = response.data.data;
        const mainDisplayField = this.collection.config.fields.find((field) => field.main_display);

        if (!mainDisplayField) {
          this.options = this.collection.items.map((item) => {
            return {
              value: {
                type: 'collection_select',
                collection_item_id: item.id,
                collection_id: this.collection.id,
                text: item.data[0].value
              },
              text: item.data[0].value,
            };
          });
        } else {
          this.options = this.collection.map((item) => {
            let mainDisplayData = item.data.find((data) => data.field === mainDisplayField.id);

            if (!mainDisplayData) {
              return false;
            }

            return {
              value: {
                type: 'collection_select',
                collection_item_id: item.id,
                collection_id: this.collection.id,
                text: mainDisplayData.value
              },
              text: mainDisplayData.value,
            };
          });
        }
      } catch (error) {
        console.error(error);
        this.$swal("錯誤", `讀取 ${this.subject.title} 選項時發生錯誤，請重新整理後再試一次`, "error");
      }
    }
  }
}
</script>
