<!-- 這個 DynamicForm 是給後台使用的，由於兩者的顯示風格差異較大，故分成兩個不同版本，不要用錯了 -->
<template>
  <div>
    <!-- FIXME: 這裡照理 hidden 要在 subjects assign to data 時就處理乾淨，不該留在 template 層清洗-->
    <div
      v-for="(subject, index) in subjects.filter((x) => {
        return x.hidden == undefined || !x.hidden;
      })"
      :key="`field.${index}`"
      class="field"
    >
      <!-- type: text start -->
      <template v-if="subject.type === 'text'">
        <b-form-group
          label-cols="12"
          label-cols-lg="2"
          label-size="sm"
          :label="subject.title"
          :label-for="subject.id"
        >
          <b-form-input
            :id="subject.id"
            class="mb-2 mr-sm-2 mb-sm-0"
            type="text"
            v-model="form[`${subject.id}`]"
            :state="v$.form[`${subject.id}`].$error ? false : null"
            :disabled="subject.disabled"
            :placeholder="displayPlaceholder(subject)"
          ></b-form-input>
          <b-form-invalid-feedback :state="!v$.form[`${subject.id}`].$error">
            輸入的資料有誤或未填寫，請確認
          </b-form-invalid-feedback>
        </b-form-group>
      </template>
      <!-- type: text end -->

      <!-- type: textarea start -->
      <template v-if="subject.type === 'textarea'">
        <b-form-group
          label-cols="12"
          label-cols-lg="2"
          label-size="sm"
          :label="subject.title"
          :label-for="subject.id"
        >
          <b-form-textarea
            :id="subject.id"
            class="mb-2 mr-sm-2 mb-sm-0"
            type="text"
            v-model="form[`${subject.id}`]"
            :placeholder="displayPlaceholder(subject)"
            :disabled="subject.disabled"
            :row="subject.rows"
            :state="v$.form[`${subject.id}`].$error ? false : null"
          ></b-form-textarea>
          <small v-if="subject.memo" class="form-text text-muted">
            {{ subject.memo }}
          </small>
          <b-form-invalid-feedback :state="!v$.form[`${subject.id}`].$error">
            輸入的資料有誤或未填寫，請確認
          </b-form-invalid-feedback>
        </b-form-group>
      </template>
      <!-- type: textarea end -->

      <!-- type: select start -->
      <template v-if="subject.type === 'select'">
        <b-form-group
          label-cols="12"
          label-cols-lg="2"
          label-size="sm"
          :label="subject.title"
          :label-for="subject.id"
        >
          <b-form-select
            v-model="form[`${subject.id}`]"
            :state="v$.form[`${subject.id}`].$error ? false : null"
            :options="optionsWithPlaceholder(subject)"
            :disabled="subject.disabled"
          ></b-form-select>
          <small v-if="subject.memo" class="form-text text-muted">
            {{ subject.memo }}
          </small>
          <b-form-invalid-feedback :state="!v$.form[`${subject.id}`].$error">
            輸入的資料有誤或未填寫，請確認
          </b-form-invalid-feedback>
        </b-form-group>
      </template>
      <!-- type: select end -->

      <!-- type: radio start -->
      <template v-if="subject.type === 'radio'">
        <b-form-group
          label-cols="12"
          label-cols-lg="2"
          label-size="sm"
          :label="subject.title"
          :label-for="subject.id"
        >
          <b-form-radio-group
            :id="subject.id"
            v-model="form[`${subject.id}`]"
            :options="subject.options"
            :disabled="subject.disabled"
            :state="v$.form[`${subject.id}`].$error ? false : null"
          ></b-form-radio-group>
          <small v-if="subject.memo" class="form-text text-muted">
            {{ subject.memo }}
          </small>
          <b-form-invalid-feedback :state="!v$.form[`${subject.id}`].$error">
            輸入的資料有誤或未填寫，請確認
          </b-form-invalid-feedback>
        </b-form-group>
      </template>
      <!-- type: radio end -->

      <!-- type: checkbox start -->
      <template v-if="subject.type === 'checkbox'">
        <b-form-group
          label-cols="12"
          label-cols-lg="2"
          label-size="sm"
          :label="subject.title"
          :label-for="subject.id"
        >
          <b-form-checkbox-group v-model="form[`${subject.id}`]">
            <b-form-checkbox
              v-for="(option, optionIndex) in subject.options"
              :key="index + '-' + optionIndex"
              :value="option.value"
              :disabled="subject.disabled"
            >
              {{ option.text }}
            </b-form-checkbox>
          </b-form-checkbox-group>
          <small v-if="subject.memo" class="form-text text-muted">
            {{ subject.memo }}
          </small>
          <b-form-invalid-feedback :state="!v$.form[`${subject.id}`].$error">
            輸入的資料有誤或未填寫，請確認
          </b-form-invalid-feedback>
        </b-form-group>
      </template>
      <!-- type: checkbox end -->

      <!-- type: collection_select start -->
      <template v-if="subject.type === 'collection_select'">
        <CollectionSelectDashboard
          :subject="subject"
          v-model="form[`${subject.id}`]"
          :validateError="v$.form[`${subject.id}`].$error"
        ></CollectionSelectDashboard>
      </template>
      <!-- type: collection_select end -->
    </div>
  </div>
</template>

<script>
import useVuelidate from "@vuelidate/core";
import { required } from "@vuelidate/validators";
import CollectionSelectDashboard from "./CollectionSelectDashboard.vue";

export default {
  components: {
    CollectionSelectDashboard,
  },
  props: {
    inputSubjects: {
      type: Array,
      default: () => {
        return [];
      },
    },
    value: Object,
  },
  watch: {
    inputSubjects: {
      handler: function (val) {
        // 這一段是避免外部父容器重複傳入 subjects 時，影像到已經渲染出來的 subjects
        // 但會導致外部父容器無法動態更新 subjects，如需要，可能要透過其他 props 來決定這段判斷是否實作
        if (this.subjects.length > 0) return;

        for (let i = 0; i < val.length; i++) {
          let subject = val[i];

          // 把 subject.config 轉換成 subject 的屬性，這裡是專門用來處理 survey_subjects 的 DB 儲存格式用的
          if (subject.config) {
            let config = subject.config;
            delete subject.config;
            val[i] = {
              ...subject,
              ...config,
            };
          }
        }

        this.subjects = val;
      },
      immediate: true,
    },
    form: {
      handler: function (val) {
        this.$emit("input", val);
      },
      immediate: true,
    },
    value: {
      handler: function (val) {
        this.form = val;
      },
      immediate: true,
    },
  },
  data() {
    return {
      subjects: [],
      form: this.value || {},
    };
  },
  setup: () => ({ v$: useVuelidate({ $lazy: true }) }),
  validations() {
    let form = {};

    for (let i = 0; i < this.subjects.length; i++) {
      let subject = this.subjects[i];
      form[`${subject.id}`] = subject.required ? { required } : {};
    }

    return {
      form,
    };
  },
  methods: {
    displayPlaceholder(subject, defaultMessage = null) {
      if (subject.placeholder) return subject.placeholder;
      if (defaultMessage !== null) return defaultMessage;
      return "";
    },
    async validate() {
      const result = await this.v$.$validate();

      if (!result) {
        console.log("validate invalid", result);
        return false;
      }

      return true;
    },
    optionsWithPlaceholder(subject) {
      if (subject.placeholder) {
        return [
          {
            text: subject.placeholder,
            value: null,
          },
          ...subject.options,
        ];
      }

      return subject.options;
    }
  },
};
</script>

<style lang="scss" scoped>
@import "@/assets/scss/shared/components/_fields.scss";

body {
  --liff-button-color: #cccccc;
}

.field {
  &__label {
    font-size: 20px;
  }
  &__input {
    font-size: 18px;
    background-color: #ffffff;
  }

  &__desc {
    font-size: 16px !important;
  }
}



.row {
  margin: 0 -0.5rem;
}
</style>
